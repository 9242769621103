<template>
  <div class="inperson-dialogue">
    <div class="reward-modal" v-if="$store.state.showRewardModal">
      <div class="reward-container">
        <div class="title-container">
          You've Earned the<br />
          {{ reward.award }} Award!
        </div>
        <div
          class="reward-images"
          :class="$store.state.roundCounter % 2 === 1 ? 'left' : 'right'"
          :style="{
            backgroundImage:
              'url(' + require('@/assets/rewards/' + reward.athlete) + ')',
          }"
        >
          <img
            class="reward-image"
            :src="require('../assets/rewards/' + reward.image)"
          />
        </div>
        <button
          class="reward-button red-buttons"
          v-on:click="goToRoundProgress"
        >
          Next
        </button>
      </div>
    </div>
    <!-- HUD -->
    <div class="top-bar">
      <Hud />
    </div>

    <div
      class="bottom-bar"
      :class="this.$store.state.mobileView ? 'mobile' : 'desktop'"
    >
      <img
        v-for="character in displayCharacters"
        :key="character.id"
        class="character"
        :class="[
          character.text.toLowerCase(),
          (currentCharacterNum &&
            characterData[
              'character-' + currentCharacterNum
            ].text.toLowerCase() === character.text.toLowerCase()) ||
          $store.state.showQuestion
            ? 'active'
            : '',
          $store.state.mobileView ? 'mobile' : 'desktop',
        ]"
        :src="
          require('../assets/characters/' +
            character.image +
            '-' +
            ($store.state.currentItem[character.image + 'face']
              ? $store.state.currentItem[character.image + 'face']
              : 'neutral') +
            '.png')
        "
        :alt="
          ($store.state.currentItem[character.image + 'face']
            ? $store.state.currentItem[character.image + 'face']
            : '') +
          ' ' +
          character.image
        "
      />
      <!-- Messages -->
      <div
        id="next-setup"
        class="conversation-component"
        tabindex="0"
        v-on:click="nextSetup($event.target)"
        :class="this.$store.state.mobileView ? 'mobile' : 'desktop'"
      >
        <div class="message-container">
          <div id="messages" class="messages">
            <div
              class="message"
              v-for="setup in setupData"
              :key="setup.id"
              :class="
                setup.character === 2 ? 'main-character' : 'other-characters'
              "
            >
              <div v-if="setup.character">
                <img
                  class="message-avatar"
                  :src="
                    require('../assets/characters/' +
                      characterData['character-' + setup.character].image +
                      '-avatar-big.png')
                  "
                  :alt="characterData['character-' + setup.character].image"
                />
              </div>
              <div
                class="character-message speech-pop"
                :class="[
                  setup.character ? 'character' + setup.character : 'narrator',
                  setup.character === 2
                    ? 'main-character right-pop'
                    : 'other-characters left-pop',
                ]"
              >
                <input
                  class="audio"
                  type="image"
                  v-on:click="$store.dispatch('playVO', setup.id)"
                  :src="require('../assets/play.png')"
                  alt="play sound"
                />
                <div>{{ setup.text }}</div>
              </div>
            </div>
            <!-- Questions and Answers in Message Div -->
            <div
              v-if="$store.state.showQuestion"
              class="question-container center-pop"
            >
              <div>
                <div class="question-text">
                  <img
                    src="../assets/characters/bobby-the-badger-avatar-big.png"
                  />
                  <Questions />
                </div>
                <div v-if="$store.state.showQuestion">
                  <Answers />
                </div>
              </div>
            </div>
            <div v-if="$store.state.showResults">
              <!-- add selected answer and selected results -->
              <div
                class="message"
                v-for="result in resultData"
                :key="result.id"
                :class="
                  result.character === 2 ? 'main-character' : 'other-characters'
                "
              >
                <div v-if="result.character">
                  <img
                    class="message-avatar"
                    :src="
                      require('../assets/characters/' +
                        characterData['character-' + result.character].image +
                        '-avatar-big.png')
                    "
                    :alt="characterData['character-' + result.character].image"
                  />
                </div>
                <div
                  class="character-message"
                  :class="[
                    result.character
                      ? 'character' + result.character
                      : 'narrator',
                    result.character === 2
                      ? 'main-character right-pop'
                      : 'other-characters left-pop',
                  ]"
                >
                  <input
                    class="audio"
                    type="image"
                    v-on:click="$store.dispatch('playVO', result.id)"
                    :src="require('../assets/play.png')"
                    alt="play sound"
                  />
                  {{ result.text }}
                </div>
              </div>
              <div
                v-if="
                  $store.state.showFeedback && $store.state.mode === 'inperson'
                "
              >
                <div class="question-container center-pop">
                  <div>
                    <div class="question-text">
                      <img
                        src="../assets/characters/bobby-the-badger-avatar-big.png"
                      />
                      <Feedback />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="this.$store.state.showRoundFeedback"
              class="round-feedback"
            >
              <div class="feedback-image"></div>
              <div class="round-feedback-right">
                <div class="round-feedback-text">
                  <div>Nice Job! You scored</div>
                  <div>{{ $store.state.roundScore + " points" }}</div>
                  <div>Keep hustling!</div>
                </div>
                <button class="red-buttons" v-on:click="showReward">
                  {{ $store.state.nextButtonText }}
                </button>
              </div>
            </div>
            <div
              v-if="
                $store.state.showFeedback &&
                (!$store.state.currentQuestionType === 'resulting-response' ||
                  ($store.state.currentQuestionType === 'resulting-response' &&
                    $store.state.decisionCounter <
                      Object.keys(
                        $store.state.content[
                          'round' + $store.state.roundCounter
                        ]
                      ).length -
                        1)) &&
                !this.$store.state.showRoundFeedback
              "
              class="nextButton"
            >
              <button
                class="yellow-buttons"
                :class="$store.state.mobileView ? 'mobile' : 'desktop'"
                v-on:click="nextQuestion"
              >
                {{ $store.state.nextButtonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Answers from "@/components/Answers.vue";
import Hud from "@/components/Hud.vue";
import Questions from "@/components/Questions.vue";
import Feedback from "@/components/Feedback.vue";
import $ from "jquery";

export default {
  name: "Conversation",
  components: {
    Answers,
    Feedback,
    Hud,
    Questions,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("getFocusableItems");
    if (this.$store.state.devMode) {
      if (
        !this.$store.state.content["round" + this.$store.state.roundCounter][
          "decision" + this.$store.state.decisionCounter
        ].setup
      ) {
        this.$store.commit("changeQuestionState", true);
      }
    }
    // autoplay first audio file
    if (this.$store.state.audio) {
      this.$store.dispatch(
        "playVO",
        this.$store.state.roundCounter +
          "-" +
          (this.$store.state.showQuestion ? "question" : "setup") +
          "-" +
          this.$store.state.decisionCounter +
          (this.$store.state.showQuestion
            ? ""
            : "-" + this.$store.state.setupCounter)
      );
    }

    // set initial expressions
    this.$store.commit("updateCurrent");
  },
  methods: {
    nextSetup(e) {
      $(window).trigger("resize");
      if (
        !$(e).hasClass("choices") &&
        !$(e).hasClass("choice-buttons") &&
        !$(e).hasClass("yellow-buttons") &&
        !$(e).hasClass("red-buttons") &&
        !$(e).hasClass("audio") &&
        !this.$store.state.showRoundFeedback
      ) {
        this.$store.commit("stopAllSounds");
        if (
          this.$store.state.content["round" + this.$store.state.roundCounter][
            "decision" + this.$store.state.decisionCounter
          ].setup &&
          this.$store.state.setupCounter ===
            Object.keys(
              this.$store.state.content[
                "round" + this.$store.state.roundCounter
              ]["decision" + this.$store.state.decisionCounter].setup
            ).length &&
          !this.$store.state.showResults
        ) {
          this.$store.commit("changeQuestionState", true);
        } else if (this.$store.state.showResults) {
          this.$store.state.resultCounter += 1;
          if (
            this.$store.state.decisionCounter ===
              Object.keys(
                this.$store.state.content[
                  "round" + this.$store.state.roundCounter
                ]
              ).length -
                1 &&
            this.$store.state.resultCounter >=
              Object.keys(this.$store.state.results).length
          ) {
            if (this.$store.state.showFeedback) {
              this.$store.state.showRoundFeedback = true;
            }
          }
        } else {
          this.$store.commit("updateCurrent");
          this.$store.state.setupCounter += 1;
        }
        // autoplay next audio
        if (this.$store.state.audio && !this.$store.state.showResults) {
          this.$store.dispatch(
            "playVO",
            this.$store.state.roundCounter +
              "-" +
              (this.$store.state.showQuestion ? "question" : "setup") +
              "-" +
              this.$store.state.decisionCounter +
              (this.$store.state.showQuestion
                ? ""
                : "-" + this.$store.state.setupCounter)
          );
        } else if (this.$store.state.audio && this.$store.state.showResults) {
          const choiceNum = this.$store.state.feedback.id.split("-")[3];
          if (
            this.$store.state.resultCounter <
            Object.keys(this.$store.state.results).length + 1
          ) {
            const result =
              this.$store.state.roundCounter +
              "-result-" +
              this.$store.state.decisionCounter +
              "-" +
              choiceNum +
              "-" +
              (this.$store.state.resultCounter - 1);
            this.$store.dispatch("playVO", result);
          }
        }
      }
    },
    nextQuestion() {
      this.$store.commit("stopAllSounds");
      this.$store.state.showRewardModal = false;
      this.$store.state.showQuestion = false;
      this.$store.state.showFeedback = false;
      this.$store.state.showResults = false;
      this.$store.state.correct = false;
      this.$store.state.setupCounter = 1;
      this.$store.state.resultCounter = 1;
      this.$store.state.results = {};
      //  check for end of decision
      if (
        this.$store.state.decisionCounter ===
        Object.keys(
          this.$store.state.content["round" + this.$store.state.roundCounter]
        ).length -
          1
      ) {
        // check for end of rounds
        if (
          this.$store.state.roundCounter <
          Object.keys(this.$store.state.content).length
        ) {
          this.nextRound();
        } else {
          this.$store.commit("navigate", { route: "Outcome", data: "" });
        }
      } else {
        this.$store.state.decisionCounter += 1;
      }
      if (
        !this.$store.state.content["round" + this.$store.state.roundCounter][
          "decision" + this.$store.state.decisionCounter
        ].setup
      ) {
        this.$store.commit("changeQuestionState", true);
      }
      this.$store.commit("updateCurrent");
      // autoplay next audio
      if (this.$store.state.audio) {
        this.$store.dispatch(
          "playVO",
          this.$store.state.roundCounter +
            "-" +
            (this.$store.state.showQuestion ? "question" : "setup") +
            "-" +
            this.$store.state.decisionCounter +
            (this.$store.state.showQuestion
              ? ""
              : "-" + this.$store.state.setupCounter)
        );
      }
      this.$store.commit(
        "setProgress",
        ((this.$store.state.decisionCounter - 1) /
          (Object.keys(
            this.$store.state.content["round" + this.$store.state.roundCounter]
          ).length -
            1)) *
          100
      );
    },
    nextRound() {
      this.$store.commit("stopAllSounds");
      this.$store.state.showRoundFeedback = false;
      this.$store.state.roundCounter += 1;
      this.$store.state.decisionCounter = 1;
      this.$store.state.setupCounter = 1;
      this.$store.state.resultCounter = 1;
      this.$store.state.roundScore = 0;
      this.$store.state.nextButtonText = "Next Question";
      window.dispatchEvent(new Event("resize"));
      if (
        this.$store.state.currentRoute === "Dialogue" &&
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.interaction === "in person"
      ) {
        this.$store.state.mode = "inperson";
      } else if (
        this.$store.state.currentRoute === "Dialogue" &&
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.interaction === "text message"
      ) {
        this.$store.state.mode = "textmessage";
      } else {
        this.$store.state.mode = "postit";
      }
    },
    showReward() {
      this.$store.commit("stopAllSounds");
      this.$store.state.showRewardModal = true;
      if (this.$store.state.audio) {
        this.$store.dispatch("playVO", this.reward.audio);
      }
    },
    goToRoundProgress() {
      window.dispatchEvent(new Event("resize"));
      this.$store.commit("stopAllSounds");
      this.$store.state.showRoundFeedback = false;
      this.$store.state.roundCounter += 1;
      this.$store.state.decisionCounter = 1;
      this.$store.state.setupCounter = 1;
      this.$store.state.resultCounter = 1;
      this.$store.state.roundScore = 0;
      this.$store.state.nextButtonText = "Next Question";
      this.$store.state.showRewardModal = false;
      this.$store.state.showQuestion = false;
      this.$store.state.showFeedback = false;
      this.$store.state.showResults = false;
      this.$store.state.correct = false;
      this.$store.state.results = {};
      this.$store.commit("setProgress", 0);
      if (
        this.$store.state.currentRoute === "Dialogue" &&
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.interaction === "in person"
      ) {
        this.$store.state.mode = "inperson";
      } else if (
        this.$store.state.currentRoute === "Dialogue" &&
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.interaction === "text message"
      ) {
        this.$store.state.mode = "textmessage";
      } else {
        this.$store.state.mode = "postit";
      }
      this.$store.commit("navigate", { route: "RoadMap", data: "" });
    },
  },
  computed: {
    setupData: function () {
      let visibleSetups = {};
      if (
        this.$store.state.content["round" + this.$store.state.roundCounter][
          "decision" + this.$store.state.decisionCounter
        ].setup
      ) {
        for (let [key, value] of Object.entries(
          this.$store.state.content["round" + this.$store.state.roundCounter][
            "decision" + this.$store.state.decisionCounter
          ].setup
        )) {
          if (key.split("-")[3] <= this.$store.state.setupCounter) {
            visibleSetups[key] = value;
          }
        }
      }
      return visibleSetups;
    },
    resultData: function () {
      let visibleResults = {};
      for (const index in Object.keys(this.$store.state.results)) {
        const key = Object.keys(this.$store.state.results)[index];
        if (index < this.$store.state.resultCounter) {
          visibleResults[key] = this.$store.state.results[key];
        }
      }
      if (
        this.$store.state.resultCounter ===
        Object.keys(this.$store.state.results).length + 1
      ) {
        this.$store.commit("updateFeedbackState");
        if (this.$store.state.audio) {
          this.$store.dispatch("playVO", this.$store.state.feedback.id);
        }
      }
      return visibleResults;
    },
    currentCharacterNum: function () {
      if (
        this.$store.state.content["round" + this.$store.state.roundCounter][
          "decision" + this.$store.state.decisionCounter
        ].setup
      ) {
        let index = this.$store.state.setupCounter;
        let key = Object.keys(
          this.$store.state.content["round" + this.$store.state.roundCounter][
            "decision" + this.$store.state.decisionCounter
          ].setup
        )[index - 1];
        return this.$store.state.content[
          "round" + this.$store.state.roundCounter
        ]["decision" + this.$store.state.decisionCounter].setup[key].character;
      } else {
        return null;
      }
    },
    characterData: function () {
      return (
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .characters || null
      );
    },
    displayCharacters: function () {
      let characters = {};
      for (let [key, value] of Object.entries(
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .characters
      )) {
        if (key !== "character-4") {
          characters[key] = value;
        }
      }
      return characters;
    },
    reward: function () {
      let reward = {};
      reward["athlete"] =
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.image + "-athlete.png";
      reward["image"] =
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.image + ".png";
      reward["award"] =
        this.$store.state.rewards[
          this.$store.state.content[
            "round" + this.$store.state.roundCounter
          ].info.title.image
        ];
      reward["audio"] =
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.image + "-award";
      return reward;
    },
  },
  updated() {
    this.$store.commit("getFocusableItems");
    if (
      this.$store.state.content["round" + this.$store.state.roundCounter][
        "decision" + this.$store.state.decisionCounter
      ].setup
    ) {
      this.$store.commit("scrollToEnd", "messages");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.conversation-component {
  font-size: clamp(12px, 2vw, 3vh);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  border: none;
  grid-row: 1;
  grid-column: 2;
  background-color: rgba(255, 255, 255, 0.26);
  box-shadow: 0px 19px 34px 19px rgb(255 255 255 / 30%);
}

.conversation-component.mobile {
  font-size: clamp(12px, 3.5vw, 3vh);
}

.message-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  grid-row: 1;
  grid-column: 1;
  padding: 25px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.question-container {
  background: white;
  margin: 20px;
  border-radius: 30px;
  padding: 20px;
  font-size: clamp(12px, 2vw, 3vh);;
}

.question-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-text > img {
  height: 4em;
}

.messages {
  justify-content: flex-end;
  text-align: left;
  overflow: auto;
  grid-row: 2;
  grid-column: 2;
}

.messages > :first-child {
  margin-top: 30px;
}

.messages > :last-child {
  margin-bottom: 30px;
}

.character-message {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.character-message.main-character,
.character-message.character2 {
  flex-direction: row-reverse;
}

.message {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.message-avatar {
  width: 4vw;
  margin: 0.5vw;
}

.nextButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-image {
  background-image: url("../assets/characters/mascot-top.png");
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.round-feedback {
  position: relative;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 1fr;
  background-color: white;
  border-radius: 20px;
  margin: 20px 0;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--light-blue);
  border-style: solid;
  border-color: white;
  border-width: 2px;
}

.round-feedback-right {
  grid-column: 2;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 0px;
}

.round-feedback-text {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--yellow);
  font-variation-settings: "wght" 900;
  align-items: center;
  margin: 1vh;
  text-align: center;
}

.mobile > * .message-avatar {
  width: 9vw;
}

.main-character {
  flex-direction: row-reverse;
}

.inperson-dialogue {
  display: grid;
  padding: 0;
  grid-template-rows: minmax(0, 1.5fr) minmax(0, 7fr);
  grid-template-columns: 2fr minmax(0, 4fr) 2fr;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  font-size: clamp(12px, 3vw, 3vh);
}

.top-bar {
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 4;
  background-color: var(--top-bar-blue);
}

.bottom-bar {
  display: grid;
  position: relative;
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 4;
  background-image: url("../assets/backgrounds/basketball.jpg");
  grid-template-columns: 2fr minmax(0, 4fr) 2fr;
  grid-template-rows: minmax(0, 1fr);
  background-size: cover;
  background-position: center top;
}

.bottom-bar.mobile {
  grid-template-rows: 3fr minmax(0, 1fr);
  grid-template-columns: 1fr 15fr 1fr;
}

.character {
  position: absolute;
  bottom: 0vh;
  overflow: hidden;
  width: 12.5vw;
  transform: translate(-50%, 0);
  filter: brightness(0.5);
  z-index: 0;
}

.felix {
  left: 6.25vw;
  max-width: 362px;
  max-height: 700px;
}

.ivy {
  left: 18.75vw;
  max-width: 347px;
  max-height: 664px;
}

.jordan {
  left: 87.5vw;
  max-width: 349px;
  max-height: 722px;
}

.character.mobile {
  height: calc(((100 / 8.5) * 7vh) / 4);
  width: auto;
}

.felix.mobile {
  left: 25%;
}

.ivy.mobile {
  left: 50%;
}

.jordan.mobile {
  left: 75%;
}

/* Ivy */
.character1 {
  border-radius: 30px;
  background-color: #f0ccff;
  color: #4c4c4c;
  padding: 20px;
}

/* Jordan */
.character2 {
  border-radius: 30px;
  background-color: #c8e07c;
  color: #4c4c4c;
  padding: 20px;
}

/* Felix */
.character3 {
  border-radius: 30px;
  background-color: #fee3ae;
  color: #4c4c4c;
  padding: 20px;
}

/* Bobby the Badger */
.character4 {
  border-radius: 30px;
  background-color: #54647d;
  color: white;
  padding: 20px;
}

.narrator {
  border-radius: 30px;
  background-color: #ffffff;
  color: var(--text-blue);
  padding: 20px;
}

.active.character {
  filter: brightness(1);
}
</style>
