var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inperson-dialogue"},[(_vm.$store.state.showRewardModal)?_c('div',{staticClass:"reward-modal"},[_c('div',{staticClass:"reward-container"},[_c('div',{staticClass:"title-container"},[_vm._v(" You've Earned the"),_c('br'),_vm._v(" "+_vm._s(_vm.reward.award)+" Award! ")]),_c('div',{staticClass:"reward-images",class:_vm.$store.state.roundCounter % 2 === 1 ? 'left' : 'right',style:({
          backgroundImage:
            'url(' + require('@/assets/rewards/' + _vm.reward.athlete) + ')',
        })},[_c('img',{staticClass:"reward-image",attrs:{"src":require('../assets/rewards/' + _vm.reward.image)}})]),_c('button',{staticClass:"reward-button red-buttons",on:{"click":_vm.goToRoundProgress}},[_vm._v(" Next ")])])]):_vm._e(),_c('div',{staticClass:"top-bar"},[_c('Hud')],1),_c('div',{staticClass:"bottom-bar",class:this.$store.state.mobileView ? 'mobile' : 'desktop'},[_vm._l((_vm.displayCharacters),function(character){return _c('img',{key:character.id,staticClass:"character",class:[
        character.text.toLowerCase(),
        (_vm.currentCharacterNum &&
          _vm.characterData[
            'character-' + _vm.currentCharacterNum
          ].text.toLowerCase() === character.text.toLowerCase()) ||
        _vm.$store.state.showQuestion
          ? 'active'
          : '',
        _vm.$store.state.mobileView ? 'mobile' : 'desktop' ],attrs:{"src":require('../assets/characters/' +
          character.image +
          '-' +
          (_vm.$store.state.currentItem[character.image + 'face']
            ? _vm.$store.state.currentItem[character.image + 'face']
            : 'neutral') +
          '.png'),"alt":(_vm.$store.state.currentItem[character.image + 'face']
          ? _vm.$store.state.currentItem[character.image + 'face']
          : '') +
        ' ' +
        character.image}})}),_c('div',{staticClass:"conversation-component",class:this.$store.state.mobileView ? 'mobile' : 'desktop',attrs:{"id":"next-setup","tabindex":"0"},on:{"click":function($event){return _vm.nextSetup($event.target)}}},[_c('div',{staticClass:"message-container"},[_c('div',{staticClass:"messages",attrs:{"id":"messages"}},[_vm._l((_vm.setupData),function(setup){return _c('div',{key:setup.id,staticClass:"message",class:setup.character === 2 ? 'main-character' : 'other-characters'},[(setup.character)?_c('div',[_c('img',{staticClass:"message-avatar",attrs:{"src":require('../assets/characters/' +
                    _vm.characterData['character-' + setup.character].image +
                    '-avatar-big.png'),"alt":_vm.characterData['character-' + setup.character].image}})]):_vm._e(),_c('div',{staticClass:"character-message speech-pop",class:[
                setup.character ? 'character' + setup.character : 'narrator',
                setup.character === 2
                  ? 'main-character right-pop'
                  : 'other-characters left-pop' ]},[_c('input',{staticClass:"audio",attrs:{"type":"image","src":require('../assets/play.png'),"alt":"play sound"},on:{"click":function($event){return _vm.$store.dispatch('playVO', setup.id)}}}),_c('div',[_vm._v(_vm._s(setup.text))])])])}),(_vm.$store.state.showQuestion)?_c('div',{staticClass:"question-container center-pop"},[_c('div',[_c('div',{staticClass:"question-text"},[_c('img',{attrs:{"src":require("../assets/characters/bobby-the-badger-avatar-big.png")}}),_c('Questions')],1),(_vm.$store.state.showQuestion)?_c('div',[_c('Answers')],1):_vm._e()])]):_vm._e(),(_vm.$store.state.showResults)?_c('div',[_vm._l((_vm.resultData),function(result){return _c('div',{key:result.id,staticClass:"message",class:result.character === 2 ? 'main-character' : 'other-characters'},[(result.character)?_c('div',[_c('img',{staticClass:"message-avatar",attrs:{"src":require('../assets/characters/' +
                      _vm.characterData['character-' + result.character].image +
                      '-avatar-big.png'),"alt":_vm.characterData['character-' + result.character].image}})]):_vm._e(),_c('div',{staticClass:"character-message",class:[
                  result.character
                    ? 'character' + result.character
                    : 'narrator',
                  result.character === 2
                    ? 'main-character right-pop'
                    : 'other-characters left-pop' ]},[_c('input',{staticClass:"audio",attrs:{"type":"image","src":require('../assets/play.png'),"alt":"play sound"},on:{"click":function($event){return _vm.$store.dispatch('playVO', result.id)}}}),_vm._v(" "+_vm._s(result.text)+" ")])])}),(
                _vm.$store.state.showFeedback && _vm.$store.state.mode === 'inperson'
              )?_c('div',[_c('div',{staticClass:"question-container center-pop"},[_c('div',[_c('div',{staticClass:"question-text"},[_c('img',{attrs:{"src":require("../assets/characters/bobby-the-badger-avatar-big.png")}}),_c('Feedback')],1)])])]):_vm._e()],2):_vm._e(),(this.$store.state.showRoundFeedback)?_c('div',{staticClass:"round-feedback"},[_c('div',{staticClass:"feedback-image"}),_c('div',{staticClass:"round-feedback-right"},[_c('div',{staticClass:"round-feedback-text"},[_c('div',[_vm._v("Nice Job! You scored")]),_c('div',[_vm._v(_vm._s(_vm.$store.state.roundScore + " points"))]),_c('div',[_vm._v("Keep hustling!")])]),_c('button',{staticClass:"red-buttons",on:{"click":_vm.showReward}},[_vm._v(" "+_vm._s(_vm.$store.state.nextButtonText)+" ")])])]):_vm._e(),(
              _vm.$store.state.showFeedback &&
              (!_vm.$store.state.currentQuestionType === 'resulting-response' ||
                (_vm.$store.state.currentQuestionType === 'resulting-response' &&
                  _vm.$store.state.decisionCounter <
                    Object.keys(
                      _vm.$store.state.content[
                        'round' + _vm.$store.state.roundCounter
                      ]
                    ).length -
                      1)) &&
              !this.$store.state.showRoundFeedback
            )?_c('div',{staticClass:"nextButton"},[_c('button',{staticClass:"yellow-buttons",class:_vm.$store.state.mobileView ? 'mobile' : 'desktop',on:{"click":_vm.nextQuestion}},[_vm._v(" "+_vm._s(_vm.$store.state.nextButtonText)+" ")])]):_vm._e()],2)])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }